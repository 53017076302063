<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="UID" prop="uid">
        <el-input
          v-model="searchForm.uid"
          clearable
          placeholder="玩家UID"
          maxlength="10"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="玩家昵称" prop="name">
        <el-input v-model="searchForm.name" clearable placeholder="玩家昵称">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="ItemIsShow(item.prop)"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage`"
            @click.native.stop="handleChangeScore(scope.row)"
            >修改积分</perButton
          >
          <perButton
            :perm="`match:stage`"
            @click.native.stop="handleChangeSort(scope.row)"
            >调整排序</perButton
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="'修改积分'"
      :visible.sync="scoreChangeVisible"
      v-if="scoreChangeVisible"
      :close-on-click-modal="false"
      width="400px"
      v-loading="loading"
    >
      <el-form
        ref="scoreForm"
        :model="scoreChangeData"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="赛事：">
          {{ match_types[type_id] }}
        </el-form-item>
        <el-form-item label="赛事阶段：" v-if="matchType != 'league'">
          {{ `第${stage}周` }}
        </el-form-item>
        <el-form-item label="玩家uid：">
          {{ scoreChangeData.uid }}
        </el-form-item>
        <el-form-item label="玩家昵称：">
          {{ scoreChangeData.name }}
        </el-form-item>
        <el-form-item label="当前积分：">
          {{ scoreChangeData.score }}
        </el-form-item>
        <el-form-item
          label="修改为："
          prop="new_score"
          label-position="left"
          :rules="[
            {
              required: true,
              message: '请输入积分',
              trigger: ['blur', 'change'],
            },
            {
              validator: scoreInteger,
              message: '请输入大于等于0的整数或2位小数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            v-model="scoreChangeData.new_score"
            placeholder="请输入积分"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveScore">确定</el-button>
          <el-button @click="scoreChangeVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="'修改同分排序'"
      :visible.sync="sortChangeVisible"
      v-if="sortChangeVisible"
      :close-on-click-modal="false"
      width="400px"
      v-loading="loading"
    >
      <el-form
        ref="sortForm"
        :model="sortChangeData"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="赛事：">
          {{ match_types[type_id] }}
        </el-form-item>
        <el-form-item label="赛事阶段：" v-if="matchType != 'league'">
          {{ `第${stage}周` }}
        </el-form-item>
        <el-form-item label="玩家uid">
          {{ sortChangeData.uid }}
        </el-form-item>
        <el-form-item label="玩家昵称：">
          {{ scoreChangeData.name }}
        </el-form-item>
        <el-form-item label="当前积分：">
          {{ scoreChangeData.score }}
        </el-form-item>

        <el-form-item
          label="排序："
          prop="sort"
          label-position="left"
          :rules="[
            {
              required: true,
              message: '请输入排序',
              trigger: ['blur', 'change'],
            },
            {
              validator: sortInteger,
              message: '请输入整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            type="number"
            v-model="sortChangeData.sort"
            placeholder="请输入排序"
            maxlength="4"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveSort">确定</el-button>
          <el-button @click="sortChangeVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { isColumnShow } from "@/utils/column";
export default {
  name: "s1_team_rank",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    ItemIsShow() {
      return function(val) {
        return isColumnShow(val, ["stage"], this.matchType);
      };
    },
  },
  props: {
    match_id: {
      type: String,
      required: true,
    },
    stage: {
      type: [String, Number],
      required: true,
    },
    rankName: {
      type: String,
      required: true,
    },
    season_id: {
      type: String,
      required: true,
    },
    group_id: {
      type: String,
      required: false,
    },
    newGroupInfo: {
      type: Array,
      required: false,
    },
  },
  watch: {
    rankName: {
      handler: function(newVal, oldVal) {
        if (newVal == "personal_rank") {
          this.new_group_id = this.group_id;
          this.handleQuery();
        }
      },
    },
    season_id: {
      handler: function(newVal, oldVal) {
        if (this.rankName == "personal_rank") {
          this.new_group_id = "";
          //如果有分组则显示第一个
          if (this.newGroupInfo.length != 0) {
            this.new_group_id = this.newGroupInfo[0]._id;
          }
          this.$emit("update:group_id", this.new_group_id);
          //更新页码，初始化
          this.pager.pageNum = 1;
          this.handleQuery();
        }
      },
    },
    group_id: {
      handler: function(newVal, oldVal) {
        if (this.rankName == "personal_rank") {
          //拿到分组信息
          this.new_group_id = newVal;
          //传给父组件
          this.$emit("update:group_id", this.new_group_id);
          //更新页码，初始化
          this.pager.pageNum = 1;
          this.handleQuery();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      new_group_id: this.group_id,
      matchType: "",
      ids: [],
      type_id: "s1",
      loading: false,
      pageSize: 10,
      scoreChangeVisible: false,
      scoreChangeData: {
        uid: "",
        sect_id: "",
        sect_name: "",
        score: "",
        new_score: "",
        name: "",
      },
      sortChangeVisible: false,
      sortChangeData: {
        uid: "",
        sect_id: "",
        sect_name: "",
        sort: "",
      },
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        uid: "",
        name: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "year",
          label: "年份",
        },
        // {
        //   prop: "",
        //   label: "赛事",
        //   width: 300,
        //   formatter: (row) => {
        //     if (this.matchType == "league") {
        //       let type_id = `${this.type_id}`;
        //       let name = type_id.split("_")[0];
        //       return `门派联赛${name}`;
        //     } else {
        //       return `门派预选赛${this.type_id}`;
        //     }
        //   },
        // },
        {
          prop: "stage",
          label: "赛事阶段",
          width: 200,
          formatter: (row) => {
            return `第${row.stage}周`;
          },
        },
        {
          prop: "uid",
          label: "玩家UID",
          width: 300,
        },
        {
          prop: "name",
          label: "玩家昵称",
          width: 200,
        },
        {
          prop: "score",
          label: "玩家积分",
          width: 200,
        },
        {
          prop: "rank",
          label: "玩家名次",
          width: 100,
        },
        {
          prop: "sort",
          label: "同分排序",
          width: 150,
        },
      ],
    };
  },
  mounted() {
    this.type_id = this.$route.query.type_id;
    this.matchType = localStorage.getItem("matchType");
    this.handleQuery();
    this.$bus.$on("onRefushList", (msg) => {
      if (msg == "person") {
        this.handleQuery();
      }
    });
  },
  methods: {
    scoreInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        callback(new Error("请输入大于等于0的整数或1位小数"));
      } else {
        callback();
      }
    },
    sortInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入整数"));
      }

      if (
        Number.isInteger(Number(value)) &&
        Number(value) >= 0 &&
        Number(value) < 10000
      ) {
        callback();
      } else {
        callback(new Error("请输入0-9999之间的整数"));
      }

      callback();
    },
    async handleChangeScore(row) {
      this.scoreChangeData.sect_id = row.sect_id;
      this.scoreChangeData.sect_name = row.sect_name;
      this.scoreChangeData.score = row.score;
      this.scoreChangeData.new_score = row.score;
      this.scoreChangeData.uid = row.uid;
      this.scoreChangeData.name = row.name;
      this.scoreChangeVisible = true;
    },
    async handleChangeSort(row) {
      this.sortChangeData.sect_id = row.sect_id;
      this.sortChangeData.sect_name = row.sect_name;
      this.sortChangeData.sort = row.sort;
      this.sortChangeData.uid = row.uid;
      this.scoreChangeData.name = row.name;
      this.scoreChangeData.new_score = row.score;
      this.scoreChangeData.score = row.score;
      this.sortChangeVisible = true;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      console.log("mmmmmmmmmmm1", this.new_group_id);
      if (this.matchType == "league" && this.season_id != "") {
        this.queryList();
      } else if (this.matchType == "preliminaries") {
        this.queryList();
      }
    },
    async queryList() {
      let res = await this.$http.match.person_team_rank({
        ...this.searchForm,
        match_id: this.match_id,
        stage: this.stage,
        pageNum: this.pager.pageNum,
        pageSize: this.pageSize,
        type_id: this.type_id,
        matchType: this.matchType,
        season_id: this.season_id,
        group_id: this.new_group_id,
      });

      this.dataTable = res.data.data;
      this.pager = res.data.pager;
    },
    async saveScore() {
      this.$refs.scoreForm.validate(async (valid) => {
        if (valid) {
          let temp = {
            match_id: this.match_id,
            stage: this.stage,
            uid: this.scoreChangeData.uid,
            score: this.scoreChangeData.new_score + "",
            type_id: this.type_id,
            matchType: this.matchType,
          };
          if (this.season_id != "") {
            temp["season_id"] = this.season_id;
          }
          let {
            data,
            errorCode,
          } = await this.$http.match.person_change_team_rank(temp);

          if (errorCode != "0000") {
            return;
          }

          this.$message.success("修改成功");
          this.handleQuery();
          this.scoreChangeVisible = false;
        }
      });
    },
    saveSort() {
      this.$refs.sortForm.validate(async (valid) => {
        if (valid) {
          let temp = {
            match_id: this.match_id,
            stage: this.stage,
            uid: this.sortChangeData.uid,
            sort: this.sortChangeData.sort + "",
            type_id: this.type_id,
            matchType: this.matchType,
          };
          if (this.season_id != "") {
            temp["season_id"] = this.season_id;
          }
          let {
            data,
            errorCode,
          } = await this.$http.match.person_change_team_sort(temp);

          if (errorCode != "0000") {
            return;
          }

          this.$message.success("修改成功");
          this.handleQuery();
          this.sortChangeVisible = false;
        }
      });
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px !important;
  height: 300px !important;
  line-height: 300px !important;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 300px !important;
  height: 300px !important;
}
.avatar {
  width: 300px !important;
  height: 300px !important;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
